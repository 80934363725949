export const TRAINING_VIDEO_LIST_REQUEST = "TRAINING_VIDEO_LIST_REQUEST";
export const TRAINING_VIDEO_LIST_SUCCESS = "TRAINING_VIDEO_LIST_SUCCESS";
export const TRAINING_VIDEO_LIST_FAIL = "TRAINING_VIDEO_LIST_FAIL";

export const TRAINING_VIDEO_LIST_ALL_REQUEST =
  "TRAINING_VIDEO_LIST_ALL_REQUEST";
export const TRAINING_VIDEO_LIST_ALL_SUCCESS =
  "TRAINING_VIDEO_LIST_ALL_SUCCESS";
export const TRAINING_VIDEO_LIST_ALL_FAIL = "TRAINING_VIDEO_LIST_ALL_FAIL";

export const TRAINING_VIDEO_LIST_BYGRADE_REQUEST =
  "TRAINING_VIDEO_LIST_BYGRADE_REQUEST";
export const TRAINING_VIDEO_LIST_BYGRADE_SUCCESS =
  "TRAINING_VIDEO_LIST_BYGRADE_SUCCESS";
export const TRAINING_VIDEO_LIST_BYGRADE_FAIL =
  "TRAINING_VIDEO_LIST_BYGRADE_FAIL";

export const TRAINING_VIDEO_DISPLAY_REQUEST = "TRAINING_VIDEO_DISPLAY_REQUEST";
export const TRAINING_VIDEO_DISPLAY_SUCCESS = "TRAINING_VIDEO_DISPLAY_SUCCESS";
export const TRAINING_VIDEO_DISPLAY_FAIL = "TRAINING_VIDEO_DISPLAY_FAIL";

export const TRAINING_VIDEO_DELETE_REQUEST = "TRAINING_VIDEO_DELETE_REQUEST";
export const TRAINING_VIDEO_DELETE_SUCCESS = "TRAINING_VIDEO_DELETE_SUCCESS";
export const TRAINING_VIDEO_DELETE_FAIL = "TRAINING_VIDEO_DELETE_FAIL";

export const TRAINING_VIDEO_UPDATE_REQUEST = "TRAINING_VIDEO_UPDATE_REQUEST";
export const TRAINING_VIDEO_UPDATE_SUCCESS = "TRAINING_VIDEO_UPDATE_SUCCESS";
export const TRAINING_VIDEO_UPDATE_FAIL = "TRAINING_VIDEO_UPDATE_FAIL";
export const TRAINING_VIDEO_UPDATE_RESET = "TRAINING_VIDEO_UPDATE_RESET";

export const TRAINING_VIDEO_CREATE_REQUEST = "TRAINING_VIDEO_CREATE_REQUEST";
export const TRAINING_VIDEO_CREATE_SUCCESS = "TRAINING_VIDEO_CREATE_SUCCESS";
export const TRAINING_VIDEO_CREATE_FAIL = "TRAINING_VIDEO_CREATE_FAIL";
export const TRAINING_VIDEO_CREATE_RESET = "TRAINING_VIDEO_CREATE_RESET";
